import { Suspense, lazy, ElementType, cloneElement, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// redux
import { updateScoreFullView } from 'redux/slices/scorecard';
import { useDispatch } from 'redux/store';
// hooks
import useAuth from 'hooks/useAuth';
// layouts
import DashboardLayout from 'layouts/dashboard';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
// guards
import GuestGuard from 'guards/GuestGuard';
import AuthGuard from 'guards/AuthGuard';
import IntroGuard from 'guards/IntroGuard';
import RoleBasedGuard from 'guards/RoleBasedGuard';
// components
import LoadingScreen from 'components/LoadingScreen';
import { PATH_PAGE } from './paths';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};
const animatedRoute = ['/hub'];
export default function Router() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === PATH_PAGE.scorecard) {
      dispatch(updateScoreFullView(false));
    }
  }, [location, dispatch]);

  useEffect(() => {
    const handleStorageChange = () => {
      if (!localStorage.getItem('accessToken')) {
        if (
          !window.location.pathname.includes(PATH_PAGE.login) &&
          !window.location.pathname.includes(PATH_PAGE.invite)
        )
          window.location.replace(PATH_PAGE.login);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Add key to make AnimatePresence work
  let routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="login" replace />,
    },
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'sign-up',
      element: (
        <GuestGuard>
          <SignUp />
        </GuestGuard>
      ),
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: 'signup-by-invitation/:token',
      element: (
        <GuestGuard shouldLogoutFirst={true}>
          <InviteUser />
        </GuestGuard>
      ),
    },
    {
      path: 'reset-password/:token',
      element: <ResetPassword />,
    },
    {
      path: 'select-plan',
      element: (
        <AuthGuard key={animatedRoute.includes(location.pathname) ? location.pathname : null}>
          <IntroGuard>
            <SelectPlan />
          </IntroGuard>
        </AuthGuard>
      ),
    },
    {
      path: 'onboarding',
      element: <Onboarding />,
    },
    {
      path: '*',
      element: (
        <AuthGuard key={animatedRoute.includes(location.pathname) ? location.pathname : null}>
          <IntroGuard>
            <DashboardLayout />
          </IntroGuard>
        </AuthGuard>
      ),
      children: [
        { path: 'scorecard', element: <Scorecard /> },
        { path: 'scorecard/:element/:option', element: <Scorecard /> },
        { path: 'scan', element: <ChatQuestionnaire /> },
        { path: 'community', element: <Community /> },

        {
          path: 'administration',
          element: (
            <RoleBasedGuard roles={'ROLE_ADMIN'}>
              <Settings />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'administration/:element',
          element: (
            <RoleBasedGuard roles={'ROLE_ADMIN'}>
              <Settings />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'administration/:element/:option',
          element: (
            <RoleBasedGuard roles={'ROLE_ADMIN'}>
              <Settings />
            </RoleBasedGuard>
          ),
        },
        { path: 'hub', element: <Hub key="hub" /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/user/profile" replace />, index: true },
            {
              path: 'profile',
              element: <UserProfile />,
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard roles={'ROLE_ADMIN'}>
                  <UserList />
                </RoleBasedGuard>
              ),
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);

  if (!routes) return null;

  routes = cloneElement(
    routes,
    routes.props.children.key ? { key: routes.props.children.key } : {}
  );

  return <AnimatePresence exitBeforeEnter>{routes}</AnimatePresence>;
}

//ERROR
const Page500 = Loadable(lazy(() => import('pages/Page500')));
const Page403 = Loadable(lazy(() => import('pages/Page403')));
const Page404 = Loadable(lazy(() => import('pages/Page404')));
// AUTHENTICATION
const Login = Loadable(lazy(() => import('pages/Login')));
const SignUp = Loadable(lazy(() => import('pages/SignUp')));
const ForgotPassword = Loadable(lazy(() => import('pages/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('pages/ResetPassword')));
// MAIN
const Scorecard = Loadable(lazy(() => import('pages/Scorecard')));
const Community = Loadable(lazy(() => import('pages/Community')));
const ChatQuestionnaire = Loadable(lazy(() => import('pages/ChatQuestionnaire')));
const Settings = Loadable(lazy(() => import('pages/Settings')));
const Hub = Loadable(lazy(() => import('pages/Hub')));

// USER
const UserProfile = Loadable(lazy(() => import('pages/user/UserProfile')));
const UserList = Loadable(lazy(() => import('pages/user/UserList')));

// INVITE
const InviteUser = Loadable(lazy(() => import('pages/InviteUser')));

// ONBOARDING
const SelectPlan = Loadable(lazy(() => import('pages/SelectPlan')));
const Onboarding = Loadable(lazy(() => import('pages/Onboarding')));
