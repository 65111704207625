// ----------------------------------------------------------------------

export const PATH_PAGE = {
  //user
  home: '/home',
  profile: '/user/profile',
  admin: '/user/admin',
  userList: '/user/list',
  organisationProfile: '/user/admin/organisation-profile',
  //auth
  login: '/login',
  signup: '/sign-up',
  resetPassword: '/reset-password',
  forgotPassword: '/forgot-password',
  //error
  page403: '/403',
  page404: '/404',
  page500: '/500',
  //main
  artificialIntelligence: '/artificial-intelligence',
  action: '/action',
  scorecard: '/scorecard/empathy/big-picture',
  community: '/community',
  scan: '/scan',
  administration: '/administration',
  account: '/profile',
  hub: '/hub',
  // invite
  invite: '/signup-by-invitation',
  // scan
  scanNew: '/scan/new',
  // payment
  payment: '/payment',
  upgrade: '/administration/organisation/upgrade',
  // onboarding
  selectPlan: '/select-plan',
  onboarding: '/onboarding',
};
