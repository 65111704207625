import { useMemo, useContext } from 'react';
//
import { AuthContext } from '../contexts/JWTContext';

//utils
import { ROLES } from 'utils/constants';

// ----------------------------------------------------------------------

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export default useAuth;

export const useIsAdmin = () => {
  const { user } = useAuth();
  const isAdmin = user ? user?.roles.includes(ROLES.ADMIN) : null;

  return useMemo(() => isAdmin, [isAdmin]);
};
