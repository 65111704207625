import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// routes
import { PATH_PAGE } from '../routes/paths';
// redux
import { useSelector } from 'redux/store';
// ----------------------------------------------------------------------

type IntroGuardProps = {
  children: ReactNode;
};

export default function IntroGuard({ children }: IntroGuardProps) {
  const { scanComplete } = useSelector((state) => state.questionnaire);
  const { profileInfo } = useSelector((state) => state.profile);
  const { scanWave } = useSelector((state) => state.scan);
  const { state, pathname } = useLocation();
  const isUpgrade = (state as any)?.isUpgrade || false;

  if (pathname !== PATH_PAGE.selectPlan && !profileInfo?.membershipTier && !isUpgrade) {
    return <Navigate to={PATH_PAGE.selectPlan} />;
  }

  if (
    pathname === PATH_PAGE.selectPlan &&
    profileInfo?.membershipTier &&
    profileInfo?.membershipTier !== 'init'
  ) {
    return <Navigate to={PATH_PAGE.scan} />;
  }

  if (scanComplete || !!scanWave?.closedAt) {
    if (pathname === PATH_PAGE.scan) {
      return <Navigate to={PATH_PAGE.hub} />;
    }
  }

  return <>{children}</>;
}
