import { createSlice } from '@reduxjs/toolkit';
// redux
import { dispatch } from 'redux/store';
// services
import * as services from 'services/scorecard';
import * as summaryServices from 'services/summary';
// @types
import { TypeElementContents, TypeElementInsights } from '_types/elements';
import {
  TypeCapabilityChart,
  TypeElementThemesChart,
  TypeScoreDepthIdentity,
  TypeScoreIdentity,
  TypeScoreInDepthIdentityJourney,
  TypeScoreInDepthIdentityMindset,
  TypeIndepthBehaviourElement,
  TypeBigPicture,
  TypeInnovationQuestion,
  TypeScoreIndepthBehaviour,
} from '_types/scorecard';
// mock data
import _elementContents from '_mock/element-content.json';
import _elementInsights from '_mock/element-insights.json';
import _themes from '_mock/themes.json';
import _bigPictureData from '_mock/big-picture.json';
import _elementData from '_mock/element-default-value.json';

// utils
import _ from 'lodash';
import camelCase from 'lodash/camelCase';
import { removeSpaces } from 'utils/formatString';
import { TypeSummary } from '_types/summary';

// ----------------------------------------------------------------------

type InitialState = {
  capabilityChart: TypeCapabilityChart[];
  isLoadingInnovationCultureCharacteristics: boolean;
  errorCapability: Error | string | null;

  ics: number;
  isLoadingICS: boolean;

  innovationQuestions: TypeInnovationQuestion[] | [];
  isLoadingInnovationPerceptions: boolean;
  errorInnovationPerceptions: Error | string | null;

  elementThemesChart: TypeElementThemesChart[] | [];
  isLoadingElementThemes: boolean;
  errorElementThemes: Error | string | null;

  scoreIdentity: TypeScoreIdentity[] | null;
  isLoadingIdentity: boolean;
  errorIdentity: Error | string | null;

  // IDENTITY IN-DEPTH
  identityActive: string;
  isLoadingInDepthIdentity: boolean;
  errorInDepthIdentity: Error | string | null;
  scoreInDepthIdentity: TypeScoreDepthIdentity[] | null;

  isLoadingInDepthIdentityJourney: boolean;
  errorInDepthIdentityJourney: Error | string | null;
  scoreInDepthIdentityJourney: TypeScoreInDepthIdentityJourney | null;

  isLoadingInDepthIdentityMindset: boolean;
  scoreIndepthIdentityMindset: TypeScoreInDepthIdentityMindset | null;
  errorInDepthIdentityMindset: Error | string | null;

  scoreFullView: boolean;
  scoreTab: string;
  identityBy: string;

  // ELEMENT IN-DEPTH VIEW
  elementActive: number;
  elementActiveName: string;
  elementInsights: TypeElementInsights[];
  elementContents: TypeElementContents[];

  scoreInDepthElements: any;
  isLoadingDepthElements: boolean;
  errorDepthElements: Error | string | null;
  navigateElement: string;

  // BEHAVIOUR IN-DEPTH VIEW
  activeInDepthBehaviourElement: string;
  activeInDepthBehaviourTheme: string;
  activeInDepthBehaviour: TypeScoreIndepthBehaviour | null;

  isLoadingInDepthBehaviours: boolean;
  scoreInDepthBehaviours: TypeIndepthBehaviourElement[];
  errorInDepthBehaviours: Error | string | null;

  isLoadingInDepthBehavioursByName: boolean;
  scoreInDepthBehavioursByName: TypeIndepthBehaviourElement | null;
  errorInDepthBehavioursByName: Error | string | null;

  activeBehaviourTypeFromBehaviour: string;
  activeBehaviourDescriptorFromBehaviour: string;

  // THEMES IN-DEPTH VIEW
  themesStaticContents: any;

  bigPicture: TypeBigPicture[];

  // SUMMARY
  summary: TypeSummary | null;
  isLoadingSummary: boolean;
  errorSummary: Error | string | null;
};

const initialState: InitialState = {
  capabilityChart: _elementData,
  isLoadingInnovationCultureCharacteristics: false,
  errorCapability: null,

  ics: 0,
  isLoadingICS: false,

  innovationQuestions: [],
  isLoadingInnovationPerceptions: false,
  errorInnovationPerceptions: null,

  elementThemesChart: [],
  isLoadingElementThemes: false,
  errorElementThemes: null,

  scoreIdentity: null,
  isLoadingIdentity: false,
  errorIdentity: null,

  // IDENTITY IN-DEPTH
  identityActive: 'Innovation Impact',
  isLoadingInDepthIdentity: false,
  errorInDepthIdentity: null,
  scoreInDepthIdentity: null,

  isLoadingInDepthIdentityJourney: false,
  errorInDepthIdentityJourney: null,
  scoreInDepthIdentityJourney: null,

  isLoadingInDepthIdentityMindset: false,
  scoreIndepthIdentityMindset: null,
  errorInDepthIdentityMindset: null,

  scoreFullView: false,
  scoreTab: 'empathyBigPicture',
  identityBy: 'department',

  // ELEMENT IN-DEPTH VIEW
  elementActive: 0,
  elementActiveName: 'Empathy',
  elementInsights: _elementInsights,
  elementContents: _elementContents,
  scoreInDepthElements: null,
  isLoadingDepthElements: false,
  errorDepthElements: null,
  navigateElement: 'empathy',

  // BEHAVIOUR IN-DEPTH VIEW
  activeInDepthBehaviourElement: '',
  activeInDepthBehaviourTheme: '',
  activeInDepthBehaviour: null,

  isLoadingInDepthBehaviours: false,
  scoreInDepthBehaviours: [],
  errorInDepthBehaviours: null,

  isLoadingInDepthBehavioursByName: false,
  scoreInDepthBehavioursByName: null,
  errorInDepthBehavioursByName: null,

  activeBehaviourTypeFromBehaviour: '',
  activeBehaviourDescriptorFromBehaviour: '',
  // THEMES IN-DEPTH VIEW
  themesStaticContents: _themes,

  bigPicture: _bigPictureData,

  summary: null,
  isLoadingSummary: false,
  errorSummary: null,
};

const slice = createSlice({
  name: 'scorecard',
  initialState,
  reducers: {
    // CAPABILITY CHART
    startLoadingInnovationCultureCharacteristics(state) {
      state.isLoadingInnovationCultureCharacteristics = true;
      state.errorCapability = null;
      state.capabilityChart = [];
    },

    hasErrorInnovationCultureScores(state, action) {
      state.isLoadingInnovationCultureCharacteristics = false;
      state.errorCapability = action.payload;
    },

    // Update innovation Culture Characteristics + global Innovation Culture Score (ICS)
    updateICS(state, data) {
      // Score by characteristic
      state.isLoadingInnovationCultureCharacteristics = false;
      state.capabilityChart = mappingElementByName(data?.payload?.characteristicsScores);
      // Global ICS
      state.isLoadingICS = false;
      state.ics = data.payload.ics;
    },

    //  SCORE INNOVATION
    startLoadingGlobalICS(state) {
      state.isLoadingICS = true;
      state.ics = 0;
    },

    //  SCORE Innovation Perceptions
    startLoadingInnovationPerceptions(state) {
      state.isLoadingInnovationPerceptions = true;
      state.errorInnovationPerceptions = null;
      state.innovationQuestions = [];
    },

    hasErrorInnovationPerceptions(state, action) {
      state.isLoadingInnovationPerceptions = false;
      state.errorInnovationPerceptions = action.payload;
    },

    updateScoreInnovationPerceptions(state, action) {
      state.isLoadingInnovationPerceptions = false;
      state.innovationQuestions = action.payload;
    },

    //  SCORE IDENTITY
    startLoadingIdentity(state) {
      state.isLoadingIdentity = true;
      state.errorIdentity = null;
      state.scoreIdentity = null;
    },

    hasErrorIdentity(state, action) {
      state.isLoadingIdentity = false;
      state.errorIdentity = action.payload;
    },

    updateScoreIdentity(state, action) {
      state.isLoadingIdentity = false;
      state.scoreIdentity = action.payload;
    },

    updateIdentityBy(state, action) {
      state.isLoadingIdentity = false;
      state.identityBy = action.payload;
    },

    // IDENTITY IN-DEPTH
    updateIdentityActive(state, action) {
      state.identityActive = action.payload;
    },

    startLoadingInDepthIdentity(state) {
      state.isLoadingInDepthIdentity = true;
      state.errorInDepthIdentity = null;
      state.scoreInDepthIdentity = null;
    },

    hasErrorInDepthIdentity(state, action) {
      state.isLoadingInDepthIdentity = false;
      state.errorInDepthIdentity = action.payload;
    },

    updateScoreInDepthIdentity(state, action) {
      state.isLoadingInDepthIdentity = false;
      state.scoreInDepthIdentity = action.payload;
    },

    startLoadingInDepthIdentityJourney(state) {
      state.isLoadingInDepthIdentityJourney = true;
      state.errorInDepthIdentityJourney = null;
      state.scoreInDepthIdentityJourney = null;
    },

    hasErrorInDepthIdentityJourney(state, action) {
      state.isLoadingInDepthIdentityJourney = false;
      state.errorInDepthIdentityJourney = action.payload;
    },

    updateScoreInDepthIdentityJourney(state, action) {
      state.isLoadingInDepthIdentityJourney = false;
      state.scoreInDepthIdentityJourney = action.payload;
    },

    startLoadingInDepthIdentityMindset(state) {
      state.isLoadingInDepthIdentityMindset = true;
      state.errorInDepthIdentityMindset = null;
    },

    hasErrorInDepthIdentityMindset(state, action) {
      state.isLoadingInDepthIdentityMindset = false;
      state.errorInDepthIdentityMindset = action.payload;
    },
    updateScoreInDepthIdentityMindset(state, action) {
      state.isLoadingInDepthIdentityMindset = false;
      state.scoreIndepthIdentityMindset = action.payload;
    },

    // ELEMENT THEMES
    startLoadingElementThemes(state) {
      state.isLoadingElementThemes = true;
      state.errorElementThemes = null;
    },

    hasErrorElementThemes(state, action) {
      state.isLoadingElementThemes = false;
      state.errorElementThemes = action.payload;
    },

    updateScoreElementThemes(state, action) {
      state.isLoadingElementThemes = false;
      state.elementThemesChart = sortElement(action.payload);
    },

    // NAVBAR
    updateScoreTab(state, action) {
      state.scoreTab = action.payload;
    },
    updateScoreFullView(state, action) {
      state.scoreFullView = action.payload;
    },

    navigateToElementView(state, action) {
      state.navigateElement = action.payload;
    },

    // ELEMENT ACTIVE
    updateElementActive(state, action) {
      state.elementActive = action.payload;
      state.activeInDepthBehaviour = null;
    },
    updateElementActiveName(state, action) {
      state.elementActiveName = action.payload;
    },

    //  SCORE ELEMENTS IN-DEPTH VIEW
    startLoadingDepthElements(state) {
      state.isLoadingDepthElements = true;
      state.errorDepthElements = null;
    },

    hasErrorDepthElement(state, action) {
      state.isLoadingDepthElements = false;
      state.errorDepthElements = action.payload;
    },

    updateScoreDepthElement(state, action) {
      state.isLoadingDepthElements = false;
      state.scoreInDepthElements = action.payload;
    },

    // BEHAVIOUR IN-DEPTH VIEW
    startLoadingInDepthBehaviours(state) {
      state.isLoadingInDepthBehaviours = true;
      state.errorInDepthBehaviours = null;
    },

    hasErrorInDepthBehaviours(state, action) {
      state.isLoadingInDepthBehaviours = false;
      state.errorInDepthBehaviours = action.payload;
    },

    updateInDepthBehaviours(state, action) {
      state.isLoadingInDepthBehaviours = false;
      state.scoreInDepthBehaviours = action.payload;
    },

    startLoadingInDepthBehavioursByName(state) {
      state.isLoadingInDepthBehavioursByName = true;
      state.errorInDepthBehavioursByName = null;
    },

    hasErrorInDepthBehavioursByName(state, action) {
      state.isLoadingInDepthBehavioursByName = false;
      state.errorInDepthBehavioursByName = action.payload;
    },

    updateInDepthBehavioursByName(state, action) {
      state.isLoadingInDepthBehavioursByName = false;
      state.scoreInDepthBehavioursByName = action.payload;
    },

    updateActiveBehaviourElement(state, action) {
      state.activeInDepthBehaviourElement = action.payload;
    },

    updateActiveBehaviourTheme(state, action) {
      state.activeInDepthBehaviourTheme = action.payload;
    },

    updateActiveBehaviour(state, action) {
      state.activeInDepthBehaviour = action.payload;
    },
    updateActiveBehaviourTypeFromBehaviour(state, action) {
      state.activeBehaviourTypeFromBehaviour = action.payload;
    },
    updateActiveBehaviourDescriptorFromBehaviour(state, action) {
      state.activeBehaviourDescriptorFromBehaviour = action.payload;
    },

    //  SUMMARY
    startLoadingSummary(state) {
      state.isLoadingSummary = true;
      state.errorSummary = null;
    },
    hasErrorSummary(state, action) {
      state.isLoadingSummary = false;
      state.errorSummary = action.payload;
    },
    updateSummary(state, action) {
      state.isLoadingSummary = false;
      state.summary = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------
// Scores by characteristic + global ICS
export function getInnovationCultureScores() {
  return () => {
    dispatch(slice.actions.startLoadingInnovationCultureCharacteristics());
    dispatch(slice.actions.startLoadingGlobalICS());
    try {
      services
        .innovationCultureScores()
        .then((response: any) => {
          dispatch(slice.actions.updateICS(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorInnovationCultureScores(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorInnovationCultureScores(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getScoreInnovationPerceptions() {
  return () => {
    dispatch(slice.actions.startLoadingInnovationPerceptions());
    try {
      services
        .scoreInnovationPerceptions()
        .then((response: any) => {
          dispatch(slice.actions.updateScoreInnovationPerceptions(response?.data?.questions));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorInnovationPerceptions(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorInnovationPerceptions(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getScoreElementThemes() {
  return () => {
    dispatch(slice.actions.startLoadingElementThemes());
    try {
      services
        .scoreElementThemes()
        .then((response: any) => {
          dispatch(slice.actions.updateScoreElementThemes(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorElementThemes(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorElementThemes(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getScoreIdentity() {
  return () => {
    dispatch(slice.actions.startLoadingIdentity());
    try {
      services
        .scoreIdentity()
        .then((response: any) => {
          dispatch(slice.actions.updateScoreIdentity(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorIdentity(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorIdentity(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateScoreTab(scoreTab: string) {
  return () => {
    dispatch(slice.actions.updateScoreTab(scoreTab));
    dispatch(slice.actions.updateScoreFullView(true));
  };
}

// ----------------------------------------------------------------------

export function updateScoreFullView(scoreFullView: boolean) {
  return () => {
    dispatch(slice.actions.updateScoreFullView(scoreFullView));
  };
}

// ----------------------------------------------------------------------

export function updateElementActive(progressActive: number) {
  return () => {
    dispatch(slice.actions.updateElementActive(progressActive));
  };
}

// ----------------------------------------------------------------------

export function updateElementActiveName(elementName: string) {
  return () => {
    dispatch(slice.actions.updateElementActiveName(elementName));
  };
}

// ----------------------------------------------------------------------

export function getScoreDepthElements(type: string) {
  return () => {
    dispatch(slice.actions.startLoadingDepthElements());
    try {
      services
        .scoreInDepthElements(type)
        .then((response: any) => {
          dispatch(slice.actions.updateScoreDepthElement(response?.data));
        })
        .catch((error: any) => {
          const status = error?.response?.status;
          if (status === 404) {
            dispatch(slice.actions.updateScoreDepthElement(null));
          } else dispatch(slice.actions.hasErrorDepthElement(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorDepthElement(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getScoreInDepthBehaviours() {
  return () => {
    dispatch(slice.actions.startLoadingInDepthBehaviours());
    try {
      services
        .scoreInDepthBehaviours()
        .then((response: any) => {
          dispatch(slice.actions.updateInDepthBehaviours(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorInDepthBehaviours(error));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasErrorInDepthBehaviours(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getScoreInDepthBehavioursByName(elementName: string) {
  return () => {
    dispatch(slice.actions.startLoadingInDepthBehavioursByName());
    try {
      services
        .scoreInDepthBehavioursByName(elementName)
        .then((response: any) => {
          dispatch(slice.actions.updateInDepthBehavioursByName(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.updateInDepthBehavioursByName(mappingBehaviours(elementName)));
          dispatch(slice.actions.hasErrorInDepthBehavioursByName(error));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasErrorInDepthBehavioursByName(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateScoreInDepthBehaviours(data: TypeIndepthBehaviourElement[]) {
  return () => {
    dispatch(slice.actions.updateInDepthBehaviours(data));
  };
}

// ----------------------------------------------------------------------

export function updateActiveBehaviourElement(element: string, theme: string = '') {
  return () => {
    dispatch(slice.actions.updateActiveBehaviourElement(removeSpaces(element).toLowerCase()));
    if (theme) dispatch(slice.actions.updateActiveBehaviourTheme(camelCase(theme)));
  };
}

// ----------------------------------------------------------------------

export function updateActiveBehaviourTheme(theme: string) {
  return () => dispatch(slice.actions.updateActiveBehaviourTheme(camelCase(theme)));
}

// ----------------------------------------------------------------------

export function updateActiveBehaviour(behaviour: TypeScoreIndepthBehaviour) {
  return () => dispatch(slice.actions.updateActiveBehaviour(behaviour));
}

// ----------------------------------------------------------------------

export function updateActiveBehaviourTypeFromBehaviour(type: string) {
  return () => dispatch(slice.actions.updateActiveBehaviourTypeFromBehaviour(type));
}

// ----------------------------------------------------------------------
export function updateActiveBehaviourDescriptorFromBehaviour(descriptor: string) {
  return () => dispatch(slice.actions.updateActiveBehaviourDescriptorFromBehaviour(descriptor));
}

// ----------------------------------------------------------------------
export function updateIdentityActive(indentity: string) {
  return () => dispatch(slice.actions.updateIdentityActive(indentity));
}

// ----------------------------------------------------------------------

export function updateIdentityBy(identity: string) {
  return () => dispatch(slice.actions.updateIdentityBy(identity));
}

// ----------------------------------------------------------------------

export function getScoreInDepthIdentity(type: string) {
  return () => {
    dispatch(slice.actions.startLoadingInDepthIdentity());
    try {
      services
        .scoreDepthIdentity(type)
        .then((response: any) => {
          dispatch(slice.actions.updateScoreInDepthIdentity(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorInDepthIdentity(error));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasErrorInDepthIdentity(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getScoreInDepthIdentityJourney(type: string) {
  return () => {
    dispatch(slice.actions.startLoadingInDepthIdentityJourney());
    try {
      services
        .scoreIdentityPerceptionsJourney(type)
        .then((response: any) => {
          dispatch(slice.actions.updateScoreInDepthIdentityJourney(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorInDepthIdentityJourney(error));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasErrorInDepthIdentityJourney(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getIndepthIdentityMindset() {
  return () => {
    dispatch(slice.actions.startLoadingInDepthIdentityMindset());
    try {
      services
        .scoreIndentityMindset()
        .then((response: any) => {
          dispatch(slice.actions.updateScoreInDepthIdentityMindset(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorInDepthIdentityMindset(error));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasErrorInDepthIdentityMindset(error));
    }
  };
}

// ----------------------------------------------------------------------

function sortElement(data: any) {
  const mappingElementName = (key: string) => {
    switch (key) {
      case 'Empathy':
        return 0;
      case 'Focus':
        return 1;
      case 'Motivation':
        return 2;
      case 'Synergy':
        return 3;
      case 'Magic':
        return 4;
      default:
        return 5;
    }
  };
  const elementArray = data?.map((item: any) => ({
    orderIndex: mappingElementName(item?.element),
    ...item,
  }));
  return _.sortBy(elementArray, (e) => e.orderIndex);
}

// ----------------------------------------------------------------------

function mappingElementByName(data: TypeCapabilityChart[]) {
  const findItem = (elementName: string) => data.find((x) => x.element === elementName);
  const elementArray = _elementData?.map((item: TypeCapabilityChart) => ({
    ...item,
    totalScorePercentage: (() => {
      const element = findItem(item.element);
      return element?.dontKnow ? undefined : element?.totalScorePercentage;
    })(),
  }));

  return elementArray;
}

// ----------------------------------------------------------------------

function mappingBehaviours(elementName: String) {
  const defaultBehaviours = {
    element: elementName,
    themes: [
      { theme: 'Identity', themeKey: 'identity', behaviours: [] },

      { theme: 'Structure', themeKey: 'structure', behaviours: [] },

      { theme: 'Habit', themeKey: 'habit', behaviours: [] },
    ],
  };
  return defaultBehaviours;
}
// ----------------------------------------------------------------------

export function getSummary(summaryType: string, characteristic: string) {
  return () => {
    dispatch(slice.actions.startLoadingSummary());
    try {
      summaryServices
        .summary(summaryType, characteristic)
        .then((response: any) => {
          dispatch(slice.actions.updateSummary(response as TypeSummary));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorSummary(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorSummary(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setSummaryFailed() {
  return () => {
    dispatch(
      slice.actions.updateSummary({
        computingState: 'error',
        summary: '',
      } as TypeSummary)
    );
  };
}

// ----------------------------------------------------------------------
