import { useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Link, Typography } from '@mui/material';
// type
import { NavItemProps } from '../type';
import { ListItemStyle as ListItem, ListItemIconStyle, LightTooltipStyle } from './style';
import { isExternalLink } from '..';
// hooks
import useAuth from 'hooks/useAuth';
// ----------------------------------------------------------------------

export function NavItemRoot({ item, active }: NavItemProps) {
  const { user } = useAuth();
  const { firstName = '', lastName = '' } = user || {};
  const [userName, setUserName] = useState(`${firstName} ${lastName}`);

  const { path, icon, disabled, caption, caption2, roles, isAcount, isSetting, onClick } = item;

  const isAdmin = user?.roles.includes('ROLE_ADMIN');

  const renderContent = (
    <>{icon && <ListItemIconStyle sx={{ margin: 0 }}>{icon}</ListItemIconStyle>}</>
  );

  useEffect(() => {
    setUserName(`${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`);
  }, [firstName, lastName]);

  const capitalizeFirstLetter = (name?: string) => {
    if (name) return name.charAt(0).toUpperCase() + name.slice(1);
  };

  if (isSetting && !isAdmin) {
    return <></>;
  }

  return isExternalLink(path) ? (
    <ListItem
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
      disabled={disabled}
      roles={roles}
      sx={{ justifyContent: 'center' }}
    >
      {renderContent}
    </ListItem>
  ) : (
    <LightTooltipStyle
      title={
        isAcount ? (
          <Typography variant="caption" component="div" sx={{ textTransform: 'initial' }}>
            <strong>{userName}</strong>
          </Typography>
        ) : caption ? (
          <Typography variant="caption" component="div" sx={{ textTransform: 'initial' }}>
            <strong>{caption}</strong> {caption2}
          </Typography>
        ) : (
          ''
        )
      }
      arrow
      placement="right"
      sx={{ inset: '0px auto auto -20px !important' }}
    >
      <span>
        <ListItem
          component={onClick ? 'div' : RouterLink}
          to={path}
          activeRoot={active}
          disabled={disabled}
          roles={roles}
          {...(onClick && { onClick })}
          sx={{ justifyContent: 'center' }}
        >
          {renderContent}
        </ListItem>
      </span>
    </LightTooltipStyle>
  );
}
