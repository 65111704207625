// components
import SvgIconStyle from 'components/SvgIconStyle';
import UserAvatar from 'components/user/UserAvatar';
import { PATH_PAGE } from 'routes/paths';

//redux
import { store } from 'redux/store';
import { setShowEmpy } from 'redux/slices/global';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  characteristics: getIcon('ic_characteristics'),
  scan: getIcon('ic_scan'),
  development: getIcon('ic_development'),
  plan: getIcon('ic_plan'),
  community: getIcon('ic_community'),
  help: getIcon('ic_help'),
  settings: getIcon('ic_settings'),
  avatar: <UserAvatar sx={{ width: 'inherit', height: 'inherit', fontSize: '1rem' }} />,
};

const navConfig = [
  // MAIN
  // ----------------------------------------------------------------------
  {
    subheader: 'Emplify',
    items: [
      {
        title: 'Characteristics',
        path: PATH_PAGE.scorecard,
        icon: ICONS.characteristics,
        caption: 'Characteristics',
        caption2: '',
      },
      {
        title: 'Scan',
        path: PATH_PAGE.scan,
        icon: ICONS.scan,
        caption: 'Scan',
      },
      {
        title: 'Plan',
        path: '',
        icon: ICONS.plan,
        caption: 'Plan',
        caption2: '- Coming Soon',
        disabled: true,
      },
      {
        title: 'Doing',
        path: '',
        icon: ICONS.development,
        caption: 'Doing',
        caption2: '- Coming Soon',
        disabled: true,
      },
    ],
  },
];
const navConfigQuestionnaire = [
  // MAIN
  // ----------------------------------------------------------------------
  {
    subheader: 'Emplify',
    items: [
      {
        title: 'Characteristics',
        path: PATH_PAGE.scorecard,
        icon: ICONS.characteristics,
        caption: 'Characteristics',
        caption2: '- Complete Scan To View',
        disabled: true,
      },
      {
        title: 'Scan',
        path: PATH_PAGE.scan,
        icon: ICONS.scan,
        caption: 'Scan',
      },
      {
        title: 'Plan',
        path: '',
        icon: ICONS.plan,
        caption: 'Plan',
        caption2: '- Coming Soon',
        disabled: true,
      },
      {
        title: 'Doing',
        path: '',
        icon: ICONS.development,
        caption: 'Doing',
        caption2: '- Coming Soon',
        disabled: true,
      },
    ],
  },
];
const navConfigScorecard = [
  // MAIN
  // ----------------------------------------------------------------------
  {
    subheader: 'Emplify',
    items: [
      {
        title: 'Characteristics',
        path: PATH_PAGE.scorecard,
        icon: ICONS.characteristics,
        caption: 'Characteristics',
      },
      {
        title: 'Scan',
        path: PATH_PAGE.scan,
        icon: ICONS.scan,
        caption: 'Scan',
        caption2: '',
        disabled: true,
      },
      {
        title: 'Plan',
        path: '',
        icon: ICONS.plan,
        caption: 'Plan',
        caption2: '- Coming Soon',
        disabled: true,
      },
      {
        title: 'Doing',
        path: '',
        icon: ICONS.development,
        caption: 'Doing',
        caption2: '- Coming Soon',
        disabled: true,
      },
    ],
  },
];

const navBottomConfig = [
  // BOTTOM
  // ----------------------------------------------------------------------
  {
    subheader: 'EmplifyBottom',
    items: [
      {
        title: 'help',
        path: '',
        icon: ICONS.help,
        caption: 'Help',
        caption2: '',
        onClick: () => store.dispatch(setShowEmpy(true)),
      },
      {
        title: 'Administration',
        path: `${PATH_PAGE.administration}/organisation/profile`,
        icon: ICONS.settings,
        caption: 'Administration',
        caption2: '',
        isSetting: true,
      },
      {
        title: 'Account',
        path: PATH_PAGE.profile,
        icon: ICONS.avatar,
        isAcount: true,
      },
    ],
  },
];
export { navConfig, navConfigQuestionnaire, navConfigScorecard, navBottomConfig };
