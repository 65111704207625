import * as api from 'utils/axios';
import * as endpoints from 'services/user/endpoints';

export const userList = () =>
  api.sendGet(endpoints.USER_LIST, {
    headers: { 'Content-Type': 'application/json', accept: 'application/json' },
    data: {},
  });

export const inviteUsers = (params: {
  emails: string[];
  message?: string;
  acceptInvitationLink: string;
}) => api.sendPost(endpoints.USER_INVITE, params);

export const checkInvitedEmails = (params: any) =>
  api.sendPost(endpoints.CHECK_INVITED_EMAIL, params);

export const removeUsers = (params: { usersEmails: string[]; invitationsEmails: string[] }) =>
  api.sendPost(endpoints.USER_REMOVE, params);

export const makeAdmin = (params: string[]) => api.sendPost(endpoints.MAKE_ADMIN, params);

export const removeAdmin = (params: string[]) => api.sendPost(endpoints.REMOVE_ADMIN, params);
