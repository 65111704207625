import { createSlice } from '@reduxjs/toolkit';
// @types
import { TypeEngagementStats } from '_types/scan';
// store
import { dispatch } from '../store';
// services
import * as services from 'services/scan';
import * as summaryServices from 'services/summary';
import * as scanWaveServices from 'services/scan-wave';
import { TypeSummary } from '../../_types/summary';
import { IScanWave } from '_types/scanWave';
// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;

  // ENGAGEMENT STATS
  engagementStats: TypeEngagementStats | null;
  isLoadingEngagementStats: boolean;
  errorEngagementStats: Error | string | null;

  // SUMMARY IDENTITY
  summaryIdentity: TypeSummary | null;
  isLoadingSummaryIdentity: boolean;
  errorSummaryIdentity: Error | string | null;

  // SUMMARY CULTURE
  summaryCulture: TypeSummary | null;
  isLoadingSummaryCulture: boolean;
  errorSummaryCulture: Error | string | null;

  // SCAN WAVE
  scanWave: IScanWave | null;
  isLoadingScanWave: boolean;
  errorScanWave: Error | string | null;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,

  engagementStats: null,
  isLoadingEngagementStats: false,
  errorEngagementStats: null,

  summaryIdentity: null,
  isLoadingSummaryIdentity: false,
  errorSummaryIdentity: null,

  summaryCulture: null,
  isLoadingSummaryCulture: false,
  errorSummaryCulture: null,

  scanWave: null,
  isLoadingScanWave: false,
  errorScanWave: null,
};

const slice = createSlice({
  name: 'scan',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    //  ENGAGEMENT STATS
    startLoadingEngagementStats(state) {
      state.isLoadingEngagementStats = true;
      state.errorEngagementStats = null;
      state.engagementStats = null;
    },
    hasErrorEngagementStats(state, action) {
      state.isLoadingEngagementStats = false;
      state.errorEngagementStats = action.payload;
    },
    updateEngagementStats(state, action) {
      state.isLoadingEngagementStats = false;
      state.engagementStats = action.payload;
    },
    //  SUMMARY IDENTITY
    startLoadingSummaryIdentity(state) {
      state.isLoadingSummaryIdentity = true;
      state.errorSummaryIdentity = null;
    },
    hasErrorSummaryIdentity(state, action) {
      state.isLoadingSummaryIdentity = false;
      state.errorSummaryIdentity = action.payload;
    },
    updateSummaryIdentity(state, action) {
      state.isLoadingSummaryIdentity = false;
      state.summaryIdentity = action.payload;
    },
    //  SUMMARY CULTURE
    startLoadingSummaryCulture(state) {
      state.isLoadingSummaryCulture = true;
      state.errorSummaryCulture = null;
    },
    hasErrorSummaryCulture(state, action) {
      state.isLoadingSummaryCulture = false;
      state.errorSummaryCulture = action.payload;
    },
    updateSummaryCulture(state, action) {
      state.isLoadingSummaryCulture = false;
      state.summaryCulture = action.payload;
    },
    //  SCAN WAVE
    startLoadingScanWave(state) {
      state.isLoadingScanWave = true;
      state.errorScanWave = null;
    },
    hasErrorScanWave(state, action) {
      state.isLoadingScanWave = false;
      state.errorScanWave = action.payload;
    },
    updateScanWave(state, action) {
      state.isLoadingScanWave = false;
      state.scanWave = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getEngagementStats() {
  return () => {
    dispatch(slice.actions.startLoadingEngagementStats());
    try {
      services
        .engagementStats()
        .then((response: any) => {
          dispatch(slice.actions.updateEngagementStats(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorEngagementStats(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorEngagementStats(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSummaryIdentity() {
  return () => {
    dispatch(slice.actions.startLoadingSummaryIdentity());
    try {
      summaryServices
        .summary('INNOVATION_IDENTITY')
        .then((response: any) => {
          dispatch(slice.actions.updateSummaryIdentity(response as TypeSummary));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorSummaryIdentity(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorSummaryIdentity(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSummaryCulture() {
  return () => {
    dispatch(slice.actions.startLoadingSummaryCulture());
    try {
      summaryServices
        .summary('INNOVATION_CULTURE')
        .then((response: any) => {
          dispatch(slice.actions.updateSummaryCulture(response as TypeSummary));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorSummaryCulture(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorSummaryCulture(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getScanWave() {
  return () => {
    dispatch(slice.actions.startLoadingScanWave());
    try {
      scanWaveServices
        .latestScanWave()
        .then((response: any) => {
          dispatch(slice.actions.updateScanWave(response.data as IScanWave));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorScanWave(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorScanWave(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setSummaryIdentityFailed() {
  return () => {
    dispatch(
      slice.actions.updateSummaryIdentity({
        computingState: 'error',
        summary: '',
      } as TypeSummary)
    );
  };
}

// ----------------------------------------------------------------------

export function setSummaryCultureFailed() {
  return () => {
    dispatch(
      slice.actions.updateSummaryCulture({
        computingState: 'error',
        summary: '',
      } as TypeSummary)
    );
  };
}

// ----------------------------------------------------------------------
