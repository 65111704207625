import { createSlice } from '@reduxjs/toolkit';
// @types
import { TypeScanInitParams, TypeQuestionnaire, TypeQuestionParams } from '_types/questionnaire';
// mock data
import _questionnaireFull from '_mock/questionnaireFull.json';
import _questionnaireStarter from '_mock/questionnaireStarter.json';
// redux
import { updateNavConfig } from 'redux/slices/global';
import { dispatch } from 'redux/store';
// services
import * as services from 'services/questionnaire';
// utils
import history from 'utils/history';
import { isNetworkError } from 'utils/retry';
// route
import { PATH_PAGE } from 'routes/paths';
// lodash
import { isEmpty } from 'lodash';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  isLoadingSurvey: boolean;
  isLoadingInit: boolean;

  error: Error | string | null;
  errorSurvey: Error | string | null;
  errorInit: Error | string | null;
  errorTimeout: Error | string | null;
  failAnswer: string[];

  scanId: string;
  progressActive: number;
  questionActive: number;
  questionSplideActive: number;
  progressQuestionComplete: boolean;
  scanComplete: boolean;
  questionnaire: TypeQuestionnaire[];
  draftAnswered: TypeQuestionParams[];

  progressNumber: number;
  elementInfoStep: number;

  isDraft: boolean;
  isContinueDraft: boolean;
  answerDetails: TypeQuestionParams[] | null;
};

const initialState: InitialState = {
  isLoading: false,
  isLoadingSurvey: false,
  isLoadingInit: false,

  error: null,
  errorSurvey: null,
  errorInit: null,
  errorTimeout: null,
  failAnswer: [],

  scanId: '',

  progressActive: 0,
  questionActive: 0,
  questionSplideActive: 0,
  progressQuestionComplete: false,
  scanComplete: false,
  questionnaire: [],
  draftAnswered: [],

  progressNumber: 0,
  elementInfoStep: 0,
  isDraft: false,
  isContinueDraft: false,
  answerDetails: null,
};

const slice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
      state.errorTimeout = null;
    },
    startLoadingSurvey(state) {
      state.isLoadingSurvey = true;
      state.errorSurvey = null;
    },
    startLoadingInit(state) {
      state.isLoadingInit = true;
      state.errorInit = null;
    },

    // HAS ERROR
    hasError(state, action) {
      if (isNetworkError(action.payload)) {
        state.failAnswer.push(JSON.parse(action.payload.config.data));
      }
      state.isLoading = false;
      state.error = action.payload;
    },
    hasErrorSurvey(state, action) {
      state.isLoadingSurvey = false;
      state.errorSurvey = action.payload;
    },
    hasErrorInit(state, action) {
      state.isLoadingInit = false;
      state.errorInit = action.payload;
    },
    hasErrorTimeout(state, action) {
      state.isLoading = false;
      state.errorTimeout = action.payload;
    },

    // SURVEY
    updateScanId(state, action) {
      state.isLoadingInit = false;
      state.isLoadingSurvey = false;
      state.scanId = action.payload;
    },
    // QUESTIONNAIRE
    initQuestionnaire(state, action) {
      const { isStarterPlan } = action.payload;
      state.questionnaire = isStarterPlan
        ? _questionnaireStarter.questions
        : _questionnaireFull.questions;
    },
    updateProgressActive(state, action) {
      state.progressActive = action.payload;
    },
    updateQuestionActive(state, action) {
      state.questionActive = action.payload;
    },
    updateQuestionSplideActive(state, action) {
      state.questionSplideActive = action.payload;
    },
    updateProgressQuestionComplete(state, action) {
      state.progressQuestionComplete = action.payload;
    },
    updateScanComplete(state, action) {
      state.scanComplete = action.payload;
    },
    updateQuestionnaire(state, action) {
      state.isLoading = false;
      if (
        state.questionnaire[state.progressActive].question?.length ===
        state.questionSplideActive + 1
      ) {
        if (state.progressActive === state.questionnaire.length - 1) {
          state.progressQuestionComplete = true;
        } else {
          state.questionSplideActive = 0;
          state.questionActive = 0;
          state.progressActive = state.progressActive + 1;
        }
      } else {
        state.questionSplideActive = state.questionActive + 1;
      }
    },

    updateElementInfoStep(state, action) {
      state.elementInfoStep = state.elementInfoStep + action.payload;
    },

    updateIsDraft(state, action) {
      state.isDraft = action.payload;
    },
    updateIsContinueDraft(state, action) {
      state.isContinueDraft = action.payload;
    },
    updateAnswerDetails(state, action) {
      state.answerDetails = action.payload;
    },
    updateScanContinue(state, action) {
      if (action.payload && action.payload?.length > 0) {
        let data = action.payload[action.payload.length - 1];

        let quesIndex = state.questionnaire[state.progressActive].question.findIndex(
          (val) => val.systemId === data?.questionId
        );
        if (quesIndex === state.questionnaire[state.progressActive].question.length - 1) {
          state.progressActive = state.progressActive + 1;
          state.questionActive = 0;
          state.questionSplideActive = 0;
        } else {
          state.questionSplideActive = quesIndex + 1;
          state.questionActive = quesIndex + 1;

          state.questionnaire[state.progressActive].question.forEach((val, index) => {
            action.payload.forEach((value: any) => {
              if (val?.systemId === value?.questionId) {
                state.questionnaire[state.progressActive].question[index] = Object.assign(
                  state.questionnaire[state.progressActive].question[index],
                  value
                );
              }
            });
          });
        }
      }
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function updateQuestionActive(questionActive: number) {
  return () => {
    dispatch(slice.actions.updateQuestionActive(questionActive));
  };
}

// ----------------------------------------------------------------------

export function updateQuestionSplideActive(questionActive: number) {
  return () => {
    dispatch(slice.actions.updateQuestionSplideActive(questionActive));
  };
}

// ----------------------------------------------------------------------

export function updateProgressQuestionComplete(progressComplete: boolean) {
  return () => {
    dispatch(slice.actions.updateProgressQuestionComplete(progressComplete));
  };
}

// ----------------------------------------------------------------------

export function updateScanComplete(isComplete: boolean) {
  return () => {
    dispatch(slice.actions.updateScanComplete(isComplete));
  };
}

// ----------------------------------------------------------------------

export function updateElementInfoStep(elementInfoStep: number) {
  return () => {
    dispatch(slice.actions.updateElementInfoStep(elementInfoStep));
  };
}

// ----------------------------------------------------------------------

export function updateProgressActive(step: number) {
  return () => {
    dispatch(slice.actions.updateProgressActive(step));
  };
}

// ----------------------------------------------------------------------

export function scanCurrent(isContinueDraft: boolean, isStarterPlan: boolean) {
  return () => {
    dispatch(slice.actions.initQuestionnaire({ isStarterPlan }));
    dispatch(slice.actions.startLoadingSurvey());
    try {
      services
        .scanCurrent()
        .then((response: any) => {
          let data = response?.data;
          if (data) {
            dispatch(slice.actions.updateScanId(data?.id));
            dispatch(slice.actions.updateScanComplete(data?.isComplete));
            if (data?.isComplete) {
              dispatch(updateNavConfig('scorecard'));
              dispatch(slice.actions.updateIsDraft(false));
            } else {
              dispatch(updateNavConfig('questionnaire'));
              if (isEmpty(data?.answerDetails) || data?.scanStatus === 'notStarted') {
                dispatch(slice.actions.updateProgressActive(0));
              } else if (data?.scanStatus === 'inProgress') {
                dispatch(slice.actions.updateIsDraft(true));

                let type: any = data?.currentStep.split('-');
                type = type?.length > 1 ? type[1] : type[0];
                let progressIndex = isStarterPlan
                  ? type === 'Innovation Culture'
                    ? 0
                    : type === 'Empathy'
                    ? 1
                    : type === 'Innovation Perceptions'
                    ? 2
                    : type === 'Profiling'
                    ? 3
                    : 4
                  : type === 'Innovation Culture'
                  ? 0
                  : type === 'Empathy'
                  ? 1
                  : type === 'Synergy'
                  ? 2
                  : type === 'Focus'
                  ? 3
                  : type === 'Motivation'
                  ? 4
                  : type === 'Magic'
                  ? 5
                  : type === 'Innovation Perceptions'
                  ? 6
                  : type === 'Profiling'
                  ? 7
                  : 8;

                if (data?.answerDetails?.length === 90) {
                  progressIndex = 8;
                }
                dispatch(slice.actions.updateProgressActive(progressIndex));
              } else {
                let type: any = data?.currentStep.split('-');
                type = type?.length > 1 ? type[1] : type[0];
                let progressIndex = isStarterPlan
                  ? type === 'Innovation Culture'
                    ? 0
                    : type === 'Empathy'
                    ? 1
                    : type === 'Innovation Perceptions'
                    ? 2
                    : type === 'Profiling'
                    ? 3
                    : 4
                  : type === 'Innovation Culture'
                  ? 0
                  : type === 'Empathy'
                  ? 1
                  : type === 'Synergy'
                  ? 2
                  : type === 'Focus'
                  ? 3
                  : type === 'Motivation'
                  ? 4
                  : type === 'Magic'
                  ? 5
                  : type === 'Innovation Perceptions'
                  ? 6
                  : type === 'Profiling'
                  ? 7
                  : 8;

                if (data?.answerDetails?.length === 90) {
                  progressIndex = 8;
                }
                dispatch(slice.actions.updateProgressActive(progressIndex));
                if (isContinueDraft) {
                  dispatch(slice.actions.updateIsContinueDraft(true));
                } else {
                  dispatch(slice.actions.updateIsDraft(true));
                }
              }
              dispatch(slice.actions.updateAnswerDetails(data?.answerDetails));
            }
          } else {
            dispatch(slice.actions.updateScanId(null));
            dispatch(updateNavConfig('questionnaire'));
          }
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorSurvey(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorSurvey(error));
    }
  };
}

// ----------------------------------------------------------------------

export function scanInit(params: TypeScanInitParams) {
  return () => {
    dispatch(slice.actions.startLoadingInit());
    try {
      services
        .scanInit(params)
        .then((response: any) => {
          dispatch(slice.actions.updateScanId(response?.data?.scanId));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasErrorInit(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasErrorInit(error));
    }
  };
}

// ----------------------------------------------------------------------

export function scanQuestion(scanId: string, paramsAnswer: TypeQuestionParams) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .scanQuestion(scanId, paramsAnswer)
        .then((response: any) => {
          dispatch(slice.actions.updateQuestionnaire(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function scanComplete(scanId: string) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .scanComplete(scanId)
        .then(() => {
          dispatch(slice.actions.updateScanComplete(true));
          dispatch(updateNavConfig('scorecard'));
          history.push(PATH_PAGE.hub);
        })
        .catch((error: any) => {
          if (error.toString().includes('timeout')) {
            dispatch(slice.actions.hasErrorTimeout(error));
          } else {
            dispatch(slice.actions.hasError(error));
          }
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function scanDraft(scanId: string, questionType?: string) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .scanDraft(scanId, questionType)
        .then((response: any) => {
          dispatch(slice.actions.updateIsContinueDraft(false));
          dispatch(slice.actions.updateScanContinue(response?.data?.answerDetails));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error));
        });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function scanContinue(answerDetails: TypeQuestionParams[] | null) {
  return () => {
    dispatch(slice.actions.updateIsDraft(false));
    dispatch(slice.actions.updateIsContinueDraft(false));
    dispatch(slice.actions.updateScanContinue(answerDetails));
  };
}

// ----------------------------------------------------------------------

export function updateIsContinueDraft(isContinueDraft: boolean) {
  return () => {
    dispatch(slice.actions.updateIsContinueDraft(isContinueDraft));
  };
}

// ----------------------------------------------------------------------

export function resetTimeout() {
  return () => {
    dispatch(slice.actions.hasErrorTimeout(null));
  };
}

// ----------------------------------------------------------------------
