import { createSlice } from '@reduxjs/toolkit';
// services
import * as services from 'services/profile';
// @types
import { ProfileInfoType } from '_types/user';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

type InitialState = {
  isLoading: boolean;
  error: Error | string | null;
  profileInfo: ProfileInfoType;
};

const initialState: InitialState = {
  isLoading: false,
  error: null,
  profileInfo: {
    id: undefined,
    username: '',
    firstName: '',
    lastName: '',
    organizationId: '',
    organizationName: '',
    displayName: '',
    mainAvatarUrl: '',
    thumbnailAvatarUrl: '',
    jobTitle: '',
    email: '',
    roles: [],
    membershipTier: 'init',
    isStarterPlan: true,
  },
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // UPDATE PROFILE INFO
    updateProfileInfo(state, action) {
      state.isLoading = false;
      const profileInfo = action.payload;
      state.profileInfo = {
        ...state.profileInfo,
        ...profileInfo,
        displayName: profileInfo?.firstName + ' ' + profileInfo?.lastName,
        isStarterPlan: profileInfo?.membershipTier === 'Emplify Starter',
      };
    },

    // UPDATE PROFILE AVATAR
    updateAvatar(state, action) {
      state.profileInfo.mainAvatarUrl = action.payload;
      state.profileInfo.thumbnailAvatarUrl = action.payload;
    },

    // DELETE PROFILE AVATAR
    deleteAvatar(state) {
      state.profileInfo.mainAvatarUrl = '';
      state.profileInfo.thumbnailAvatarUrl = '';
    },

    // UPDATE PROFILE JOB TITLE
    updateJobTitle(state, action) {
      state.isLoading = false;
      state.profileInfo.jobTitle = action.payload?.jobTitle;
    },

    // UPDATE ORGANIZATION
    updateOrganization(state, action) {
      const organization = action.payload;
      state.profileInfo.organizationId = organization?.id;
      state.profileInfo.organizationName = organization?.name;
    },

    // UPDATE ORGANIZATION MEMBERSHIP
    updateOrganizationMembership(state, action) {
      //So IntroGuard can now behave accordingly
      // BE response is error please help me to update response BE.
      state.profileInfo.membershipTier = 'Emplify Starter';
      state.profileInfo.isStarterPlan = true;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getProfileInfo() {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .profileInfoFull()
        .then((response: any) => {
          dispatch(slice.actions.updateProfileInfo(response));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateProfileAvatar(params: { fileName: string; base64Content: string } | string) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      return services
        .updateProfileAvatar(params)
        .then((response: any) => {
          dispatch(getProfileInfo());
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteProfileAvatar() {
  return () => {
    try {
      services
        .deleteProfileAvatar()
        .then((response: any) => {
          dispatch(slice.actions.deleteAvatar());
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateProfileInfo(params: Partial<ProfileInfoType>) {
  return () => {
    dispatch(slice.actions.startLoading());
    return services
      .updateProfileInfo(params)
      .then((response: any) => {
        dispatch(slice.actions.updateProfileInfo(response?.data));
        return response?.data;
      })
      .catch((error: any) => {
        dispatch(slice.actions.hasError(error?.data));
      });
  };
}

// ----------------------------------------------------------------------

export function updateJobTitle(params: { jobTitle: string }) {
  return () => {
    dispatch(slice.actions.startLoading());
    try {
      services
        .updateJobTitle(params)
        .then((response: any) => {
          dispatch(slice.actions.updateJobTitle(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateOrganization(params: { newName: string }) {
  return () => {
    try {
      services
        .updateOrganizationName(params)
        .then((response: any) => {
          dispatch(slice.actions.updateOrganization(response?.data));
        })
        .catch((error: any) => {
          dispatch(slice.actions.hasError(error));
        });
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
